const config = {
  NODE_ENV: "local",
  // NODE_ENV: 'production',
  // NODE_ENV: 'production',
  local: {
    domain: "amazingco.au.auth0.com",
    clientId: "ST2ZPUcqa2FP0AKNx8TnFUIlJMXmU7jZ",
    callbackUrl: "http://localhost:3000/callback",
  },
  development: {
    domain: "amazingco.au.auth0.com",
    clientId: "ST2ZPUcqa2FP0AKNx8TnFUIlJMXmU7jZ",
    callbackUrl:
      "http://ac-auth-dev.s3-website-ap-southeast-2.amazonaws.com/callback",
  },
  production: {
    domain: "amazingco.au.auth0.com",
    clientId: "3C1wOGxSUdk4bm6Zr5ehYJJ1pqYSTzvW",
    callbackUrl: "http://auth.amazingco.me/callback",
  },
};

export default config;
