import React, { Component } from "react";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onLogin = this.onLogin.bind(this);
    this.onUpdateDetails = this.onUpdateDetails.bind(this);
  }

  onLogin() {
    this.props.auth.login();

    // const userId = "1";
    // const url = `https://user-api.amazingco.me/api/v1/users/${userId}`;
    // const config = {
    //   method: "GET",
    //   mode: "cors",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `${token.tokenType} ${token.accessToken}`,
    //   },
    // };

    // axios.get(url, config).then((response) => {
    //   if (response.data && !isEmpty(response.data)) {
    //     const user = response.data[0];
    //     if (user) {
    //       alert("User exists");
    //       this.setState({ isModifyingLogin: false });
    //     } else {
    //       this.setState({ isModifyingLogin: true });
    //     }
    //   }
    // });
  }

  onUpdateDetails() {
    console.log("//TODO: Update user details by caller user API and not auth0");

    // const userId = "1";
    // const url = `https://user-api.amazingco.me/api/v1/users/${userId}`;
    // const config = {
    //   method: "PUT",
    //   mode: "cors",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `${token.tokenType} ${token.accessToken}`,
    //   },
    // };

    // axios.put(url, config).then((response) => {
    //   if (response.data && !isEmpty(response.data)) {
    //     const isSuccessful = response.data;
    //     if (isSuccessful) {
    //       alert("User has been updated");
    //     } else {
    //       console.log("Error:", response);
    //     }
    //   }
    // });

    alert("User has been updated");
  }

  renderUserDetail() {
    return (
      <div>
        <h4>You are logged in !</h4>
        <br />
        <div>
          <div>
            First Name: &nbsp;
            <input type="text" />
          </div>
          <div>
            Last Name: &nbsp;
            <input type="text" />
          </div>
          <div>
            Date of Birth: &nbsp;
            <input type="date" />
          </div>
          <div>
            Notification: &nbsp;
            <input type="radio" id="sms" name="notification" /> SMS &nbsp;
            <input type="radio" id="email" name="notification" /> Email &nbsp;
            <input type="radio" id="push" name="notification" /> Push &nbsp;
          </div>
          <br />
          <div>
            <button
              style={{
                cursor: "pointer",
              }}
              onClick={this.onUpdateDetails}
            >
              Update Details
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderNotLogin() {
    return (
      <div>
        <h4>You are not logged in!</h4>
        <br />
        Please &nbsp;
        <button
          style={{
            cursor: "pointer",
          }}
          onClick={this.login}
        >
          Log In
        </button>
        &nbsp; to continue.
      </div>
    );
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    return (
      <div className="container">
        {isAuthenticated() && this.renderUserDetail()}
        {!isAuthenticated() && this.renderNotLogin()}
      </div>
    );
  }
}

export default Home;
